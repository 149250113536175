import request from '@/utils/request'
const application = '/service-appManage-provider/v1/desktop/app'
const session = '/service-appManage-provider/v1/desktop/Session'
const questionUrl = '/service-appManage-provider/v1'
// 查询全部分类
export function queryTypeList() {
  return request({
    url: application + '/type/list/all',
    method: 'get'
  })
}
// 查询个人应用
export function queryPersonApp() {
  return request({
    url: application + '/list/person',
    method: 'get'
  })
}
// 已安装
export function installApp(keyWord) {
  return request({
    url: application + '/list/person?keyWord=' + keyWord,
    method: 'get'
  })
}

// 查询桌面应用
export function queryDesktopApp() {
  return request({
    url: application + '/list/person/desktop?link=true',
    method: 'get'
  })
}
// 根据分类查询应用
export function queryAppByType(tId, keyWord) {
  return request({
    url: application + '/list/store?typeId=' + tId + '&keyWord=' + keyWord,
    method: 'get'
  })
}
// 删除桌面应用
export function uninstallApp(id) {
  return request({
    url: application + '/del/person/desktop?appId=' + id,
    method: 'delete'
  })
}
// 新增个人应用
export function addPersonApp(data) {
  return request({
    url: application + '/new/person',
    method: 'post',
    data
  })
}
// 更新应用是否可见
export function isLink(data) {
  return request({
    url: application + '/update/person/link',
    method: 'patch',
    data
  })
}
// 修改应用弹出框的宽高
export function updateWindowSize(data) {
  return request({
    url: application + '/detail/size',
    method: 'patch',
    data
  })
}

// 新建session
export function addSession(data) {
  return request({
    url: session + '/new',
    method: 'post',
    data
  })
}
// 根据条件查session
export function querySession(data) {
  return request({
    url: session + '/list/uid/type',
    method: 'post',
    data
  })
}
// 删除会话
export function deleteSession(data) {
  return request({
    url: session + '/del/',
    method: 'delete',
    data
  })
}
// 问题反馈管理
export function question(data) {
  return request({
    url: questionUrl + '/question',
    method: 'POST',
    data
  })
}
