<template>
  <div id="monitor-container">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: 'Monitor',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('load', e => this.loadHandler(e))
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('load', e => this.loadHandler(e))
  },
  methods: {
    beforeunloadHandler(e) {
      e = e || window.event
      if (localStorage.uploadFileLength !== undefined && localStorage.uploadFileLength != 0) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示'
    },
    loadHandler() {
      localStorage.uploadFileLength = 0
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less"></style>
