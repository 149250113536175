import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import store from '@/store'
import DemoLayout from '@/components/Demo'

const routes = [{
  path: '/desktop',
  meta: { title: store.state.core.name },
  component: () => import('../views/Desktop.vue')
},
{
  path: '/',
  // redirect: '/login',
  component: () => import('../views/login/index.vue')
},
{
  path: '/register',
  component: () => import('../views/register/bind.vue')
},
{
  path: '/registerUser',
  component: () => import('../views/register/register.vue')
},
{
  path: '/personal-info',
  component: DemoLayout,
  hidden: true,
  name: 'FindPersonalInfo',
  meta: { title: '找回密码', icon: 'file-transfer', activedIcon: 'actived-file-transfer' },
  children: [
    {
      path: 'find-password',
      component: () => import('@/views/find-password/FindPassword'),
      hidden: true,
      name: 'FindPassword',
      meta: { title: '找回密码', icon: 'file-transfer', activedIcon: 'actived-file-transfer' }
    },
    {
      path: 'change-find-type',
      component: () => import('@/views/find-password/ChangeFindType'),
      hidden: true,
      name: 'ChangeFindType',
      meta: { title: '找回密码', icon: 'file-transfer', activedIcon: 'actived-file-transfer' }
    },
    {
      path: 'phone-find',
      component: () => import('@/views/find-password/PhoneFind'),
      hidden: true,
      name: 'PhoneFind',
      meta: { title: '找回密码', icon: 'file-transfer', activedIcon: 'actived-file-transfer' }
    },
    {
      path: 'update-password',
      component: () => import('@/views/find-password/UpdatePassword'),
      hidden: true,
      name: 'UpdatePassword',
      meta: { title: '找回密码', icon: 'file-transfer', activedIcon: 'actived-file-transfer' }
    },
    {
      path: 'appeal-find',
      component: () => import('@/views/find-password/AppealFind'),
      hidden: true,
      name: 'AppealFind',
      meta: { title: '找回密码', icon: 'file-transfer', activedIcon: 'actived-file-transfer' }
    }
  ]
},
{
  path: '/zfbPayment',
  component: () => import('@/views/recharge/PaymentZfb'),
  hidden: true
},
{
  path: '/recharge',
  component: () => import('@/views/recharge/recharge'),
  hidden: true
},
{
  path: '/callBack',
  component: () => import('../views/Callback.vue')
},
{
  path: '/userAgreement',
  component: () => import('../views/UserAgreement.vue')
},
{
  path: '/rechargeAgreement',
  component: () => import('../views/RechargeAgreement.vue')
},
{
  path: '/authentication',
  component: () => import('@/views/Authentication'),
  hidden: true
}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }
export default router
