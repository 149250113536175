
const CryptoJS = require('crypto-js')

// 解密方法
export function Decrypt(data) {
  const key = CryptoJS.enc.Utf8.parse('AD42F6697B035B7580E4FEF93BE20BAD')
  const decrypt = CryptoJS.AES.decrypt(data, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
