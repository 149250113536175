import requestEhpc from '@/utils/requestEhpc'
const region = '/service-region-provider/v1'

// 查询域
export function getRegionList() {
  return requestEhpc({
    url: region + '/region',
    method: 'get'
  })
}
