<template>
  <div class="demo-layout">
    <div class="header">
      <!-- <img class="img" src="@/assets/image/uia-logo.png" alt=""> -->
      <svg-icon icon-class="title" class-name="svg" class="title" />
      <div style="color: #fff;font-size: 16px;line-height: 64px;">{{ title }}</div>
    </div>
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key" class="cont" />
    </transition>
    <div class="footer">
      COPYRIGHT © 山东正云信息科技有限公司 jengcloud.com 鲁ICP备20000303号
    </div>
  </div>
</template>

<script>
export default {
  name: 'Demo',
  computed: {
    key() {
      return this.$route.path
    },
    title() {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.demo-layout {
  height: 100vh;
  width: 100%;
  background: #f5f5f5;
  overflow: hidden;
  overflow-x: auto;
  .header {
    width: 100%;
    min-width: 880px;
    height: 64px;
    background: #1c1a28;
    line-height: 64px;
    display: flex;
    .img {
      height: 44px;
      margin: 10px 32px 0;
    }
    .title{
      width: 114px;
      height: 40px;
      margin: 10px 32px 0;
    }
  }
  .cont {
    height: calc(100% - 160px);
    min-width: 880px;
    overflow: hidden;
    overflow-y: auto;
    font-size: 12px;
    color: #313a46;
  }
  .footer {
    width: 100%;
    min-width: 880px;
    height: 96px;
    line-height: 96px;
    font-size: 12px;
    color: #737d85;
    text-align: center;
    background: #121c31;
  }
}
</style>
