import {
  createStore
} from 'vuex'
import getters from './getters'
import core from './core.js'
import message from './message.js'
import user from './user.js'
import downLoadProgress from './downLoadProgress'
import unzip from './unzip'

export default createStore({
  modules: {
    core, message, user, downLoadProgress, unzip
  },
  state: {
    requests: [] // 请求队列
  },
  getters
})
