const state = {
  progressList: new Map(), // 文件下载进度列表
  pauseFunc: new Map(), // 暂停后暂存的上传方法
  isPause: new Map(), // 暂停标志位
  continueUid: 0, // 继续上传的uid
  cancelTokenArr: []
}

const mutations = {
  SET_PROGRESS: (state, progressObj) => { // 修改进度列表
    state.progressList.set(progressObj.uid, progressObj)
  },
  DEL_PROGRESS: (state, progressObj) => {
    state.progressList.delete(progressObj.uid)
  },
  SET_ISPAUSE: (state, props) => {
    state.isPause.set(props.uid, props.state) // 设置暂停标志
  },
  SET_PAUSEFUNC: (state, props) => {
    state.pauseFunc.set(props.uid, props.funcArray) // 设置 该文件的暂停标志位置
  },
  SET_CONTINUEUID: (state, props) => {
    state.continueUid = props // 设置 继续上传的uid
  },
  ADD_CANCELTOKEN(state, cancel) {
    if (!state.cancelTokenArr) {
      state.cancelTokenArr = []
    }
    if (cancel) {
      state.cancelTokenArr.push(cancel)
    }
  },
  // 取消所有请求
  CLEAR_CANCELTOKEN(state) {
    state.cancelTokenArr.forEach(c => {
      if (c) {
        c()
      }
    })
    state.cancelTokenArr = []
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
