const state = {
  unzipList: [] // 文件解压缩列表
}

const mutations = {
  SET_UNZIP: (state, unzipObj) => { // 修改列表
    if (state.unzipList.length) { // 如果列表存在
      if (state.unzipList.find(item => item.time == unzipObj.time)) { // 时间戳是唯一存在的，所以如果在进度列表中找到当前的进度对象
        state.unzipList.find(item => item.time == unzipObj.time).progress = unzipObj.progress // 改变当前进度对象的progress
      } else {
        state.unzipList.push(unzipObj) // 当前任务列表内没有以该时间戳存在的任务，向任务列表中添加
      }
    } else {
      state.unzipList.push(unzipObj) // 当前进度列表为空，没有任务，直接将该进度对象添加到进度数组内
    }
  },
  DEL_UNZIP: (state, props) => {
    state.unzipList.splice(state.unzipList.findIndex(item => item.time == props), 1) // 删除进度列表中的进度对象
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
