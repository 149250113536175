import { createApp } from 'vue'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Monitor from './Monitor.vue'
import router from './router'
import store from './store'
import '@/permission'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VForm3 from 'vform3-builds' // 引入VForm3库
import 'vform3-builds/dist/designer.style.css'
import animate from 'animate.css'
import './style.less'
import './icon.less'
import 'font-awesome/css/font-awesome.css'
// 引入svg组件
import SvgIcon from './components/SvgIcon'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(Monitor)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.productionTip = false
app.use(ElementPlus, { locale })
app.use(VForm3)
app.use(Antd)
app.use(VueViewer)
app.component('SvgIcon', SvgIcon)
const req = require.context('@/icons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
app.use(store)
app.use(router)
app.use(animate)
// app._context.components.ElDrawer['props'].closeOnClickModal.default = false
app.mount('#monitor')
