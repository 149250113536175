import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRouteName() {
  return Cookies.get('routeName')
}

export function setRouteName(routeName) {
  return Cookies.set('routeName', routeName)
}
